<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
        'login-forgot-username-on': this.state == 'forgot_username',
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h5
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h5 font-size-h3-lg
                  "
                >
                  {{ pageTitle }}
                </h5>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5 mb-4"
                    >Username</label
                  >
                  <b-button
                    class="font-size-h6 text-primary font-weight-bolder"
                    size="sm"
                    variant="link"
                    id="kt_login_forgot_username"
                    @click="showForm('forgot_username')"
                    >Forgot Username ?
                  </b-button>
                </div>
                <base-input
                  extra-class=" h-auto py-7 px-6"
                  v-model="form.username"
                  hide-label
                  label-class="d-none"
                  label="Username"
                  :validator="$v.form.username"
                  :messages="localMessages"
                ></base-input>
              </div>

              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <b-button
                    class="font-size-h6 text-primary font-weight-bolder"
                    size="sm"
                    variant="link"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Forgot Password ?
                  </b-button>
                </div>
                <base-password
                  extra-class=" h-auto py-7 px-6"
                  v-model="form.password"
                  hide-label
                  label="Password"
                  :validator="$v.form.password"
                  :messages="localMessages"
                ></base-password>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  @click.prevent="onLogin"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Log In
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Sign Up
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                  style="width: 150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Back to Login
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <base-input
                extra-class=" h-auto py-7 px-6"
                v-model="forgotPasswordForm.email"
                hide-label
                placeholder="Enter your email"
                label-class="d-none"
                label="Email"
                :validator="$v.forgotPasswordForm.email"
                :messages="localMessages"
              ></base-input>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  @click.prevent="onForgotPassword"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Reset Password
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Back to Login
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot-username">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_username_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Forgotten Username ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to retrieve your username
                </p>
              </div>
              <base-input
                extra-class=" h-auto py-7 px-6"
                v-model="forgotUsernameForm.email"
                hide-label
                placeholder="Enter your email"
                label-class="d-none"
                label="Email"
                :validator="$v.forgotUsernameForm.email"
                :messages="localMessages"
              ></base-input>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  @click.prevent="onForgotUsername"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Retrieve Username
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_username_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Back to Login
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// FormValidation plugins
import KTUtil from "@/assets/js/components/util";
import {
  FORGOT_USERNAME,
  // eslint-disable-next-line no-unused-vars
  LOGIN,
  // eslint-disable-next-line no-unused-vars
  LOGIN_CLIENT_ADMIN,
  // eslint-disable-next-line no-unused-vars
  LOGIN_GLOBAL_ADMIN,
  // eslint-disable-next-line no-unused-vars
  LOGIN_SUPER_ADMIN,
  // eslint-disable-next-line no-unused-vars
  LOGIN_TRAINEE_USER,
  // eslint-disable-next-line no-unused-vars
  LOGOUT,
  PRE_LOGIN,
  // eslint-disable-next-line no-unused-vars
  REGISTER,
  RESET_PASSWORD,
} from "@/core/services/store/modules/auth.module";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { LOGIN_CONFIG } from "@/core/config/config";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      systemTypeId: 3,
      pageTitle: "Global Admin Login",
      state: "signin",
      // Remove this dummy login info
      form: {
        username: "nizam_ga",
        password: "@Bcd1234",
      },
      forgotUsernameForm: {
        email: "",
      },
      forgotPasswordForm: {
        email: "",
      },
    };
  },
  validations: {
    form: {
      username: { required },
      password: { required },
    },
    forgotUsernameForm: {
      email: { required, email },
    },
    forgotPasswordForm: {
      email: { required, email },
    },
  },
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {},
  methods: {
    showForm(form) {
      this.state = form;
      this.resetForm();
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    resetForm() {
      this.$v.form.$reset();
      this.form = {
        username: "nizam_ga",
        password: "@Bcd1234",
      };
      this.$v.forgotUsernameForm.$reset();
      this.forgotUsernameForm = {
        email: "",
      };
      this.$v.forgotPasswordForm.$reset();
      this.forgotPasswordForm = {
        email: "",
      };
    },
    onForgotPassword() {
      this.$v.forgotPasswordForm.$touch();
      if (this.$v.forgotPasswordForm.$anyError) {
        return;
      } else {
        let email = this.forgotPasswordForm.email;
        let payload = {
          email: email,
          system_type_id: this.systemTypeId,
        };

        this.$store.dispatch(RESET_PASSWORD, payload).then(() => {
          SystemHelper.basicSwalHandler(
            "Success",
            "Instructions have been sent to the email address. Please check your email"
          );
          this.resetForm();
        });
      }
    },
    onForgotUsername() {
      this.$v.forgotUsernameForm.$touch();
      if (this.$v.forgotUsernameForm.$anyError) {
        return;
      } else {
        let email = this.forgotUsernameForm.email;

        let payload = {
          email: email,
          system_type_id: this.systemTypeId,
        };
        this.$store.dispatch(FORGOT_USERNAME, payload).then(() => {
          SystemHelper.basicSwalHandler(
            "Success",
            "Instructions have been sent to the email address. Please check your email"
          );
          this.resetForm();
        });
      }
    },
    onLogin() {
      console.log("clicked");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        let username = this.form.username;
        let password = this.form.password;

        // clear existing errors
        this.$store.dispatch(PRE_LOGIN);

        let payload = {
          username: username,
          password: password,
          system_type_id: this.systemTypeId,
          loginChannelId: LOGIN_CONFIG.LOGIN_CHANNEL_ID,
        };
        this.$store
          .dispatch(LOGIN_GLOBAL_ADMIN, payload)
          // go to which page after successfully login
          .then((result) => {
            console.log(result);
            console.log(payload);
            if (result.response_code === 2100) {
              if (payload.loginChannelId === 3) {
                this.$router.replace({ name: "otp-page" });
              } else if (payload.loginChannelId === 2) {
                this.$router.replace({ name: "otp-page" });
              } else {
                this.$router.replace({ name: "dashboard" });
              }
            }
          });
      }
    },
  },
};
</script>
